/* eslint-disable */
<template>
  <v-main v-if="onboardInProgress">
    <v-layout text-center wrap>
      <v-flex>
        <span style="color: lime; font-size: 20px">
          <v-icon x-large color="amber">mdi-information-outline</v-icon>
          Service Provider Onboarding is in progress
        </span>
        <v-progress-circular
          :size="40"
          :width="7"
          color="amber"
          indeterminate
        ></v-progress-circular>
      </v-flex>
    </v-layout>
  </v-main>
  <v-main v-else>
    <v-card class="mx-auto" color="grey darken-4">
      <v-card-title class="justify-center">
        <h3 style="color: lime; text-align: right">
          Service Provider Registration
        </h3>
      </v-card-title>
      <div class="text-center" style="color: yellow">
        <v-icon small color="amber">mdi-information</v-icon>
        If you refresh this page before submitting the form, you will loose the
        form data and session.
      </div>
      <form class="signup-form">
        <v-divider color="amber" class="my-4 mx-4"></v-divider>
        <div class="text-center" style="color: lime">
          <v-icon dark mr-5>mdi-account</v-icon>Service Provider Personal
          Information
        </div>
        <v-text-field
          v-model="firstName"
          label="First Name"
          :counter="10"
          :error-messages="firstNameErrors"
          dark
          required
          @input="$v.firstName.$touch()"
          @blur="$v.firstName.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="lastName"
          label="Last Name"
          :counter="10"
          :error-messages="lastNameErrors"
          dark
          required
          @input="$v.lastName.$touch()"
          @blur="$v.lastName.$touch()"
        ></v-text-field>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                dark
                v-model="gregorianDate"
                label="Date of Birth"
                readonly
                @click="openDatePicker"
                @input="$v.pickedDate.$touch()"
                @blur="$v.pickedDate.$touch()"
              />
              <v-ethiopian-date-picker
                v-if="showDatePicker"
                v-model="pickedDate"
                :min="dobMin"
                :max="dobMax"
                locale="am"
                @change="convertDate"
                @close-picker="closeDatePicker"
              />
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-text-field
          v-model="age"
          label="Age"
          dark
          required
          :error-messages="ageErrors"
          @input="$v.age.$touch()"
          @blur="$v.age.$touch()"
        ></v-text-field> -->
        <v-autocomplete
          v-model="gender"
          label="Gender"
          :items="genders"
          dark
          required
          :error-messages="genderErrors"
          @input="$v.gender.$touch()"
          @blur="$v.gender.$touch()"
        ></v-autocomplete>
        <v-text-field
          v-model="email"
          label="Email Adress"
          dark
          required
          :error-messages="emailErrors"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        ></v-text-field>
        <!-- National ID  -->
        <v-text-field
          v-model="nationalId"
          label="National ID (FAN)"
          outlined
          dense
          dark
          maxlength="16"
          hint="Enter a 16-digit National ID Number"
        ></v-text-field>
        <v-autocomplete
          v-model="educationalLevel"
          label="Educational Level"
          :items="educationalLevels"
          dark
          required
          :error-messages="educationalLevelErrors"
          @input="$v.educationalLevel.$touch()"
          @blur="$v.educationalLevel.$touch()"
        >
        </v-autocomplete>
        <h4 class="text-center" style="color: white">Profile Picture</h4>
        <span
          v-if="$v.profilePictureImageData.$invalid"
          style="color: lime; font-size: 12px"
          mb-4
        >
          <v-icon small color="amber">mdi-alert</v-icon>
          Ad a profile picture of the service provider (shoulder-up headshot)
        </span>
        <image-cropping
          :error-messages="profilePictureErrors"
          @load="$v.profilePictureImageData.$touch()"
          @input="$v.profilePictureImageData.$touch()"
          @blur="$v.profilePictureImageData.$touch()"
          formLabel="Service Provider Profile Picture"
          v-on:listingPicture="profilePictureImageData = $event"
        ></image-cropping>
        <h4 class="text-center" style="color: white">ID document Picture</h4>
        <span
          v-if="$v.identificationDocImageData.$invalid"
          style="color: lime; font-size: 12px"
          mb-4
        >
          <v-icon small color="amber">mdi-alert</v-icon>
          Add any form of picture identification document (Kebele, School, Work,
          Passport...)
        </span>
        <image-cropping
          :error-messages="idDocPictureErrors"
          @load="$v.identificationDocImageData.$touch()"
          @input="$v.identificationDocImageData.$touch()"
          @blur="$v.identificationDocImageData.$touch()"
          formLabel="Identification Document"
          v-on:listingPicture="identificationDocImageData = $event"
        ></image-cropping>
        <!-- <v-autocomplete
        prepend-icon="mdi-city"
        v-model="city"
        :items="cities"
        :label="`City  ${ !isEditing ? 'Enter your city' : ''}`"
        :error-messages="cityErrors"
        dark
        required
        @click="isEditing = !isEditing"
        @input="$v.city.$touch()"
      @blur="$v.city.$touch()"></v-autocomplete>-->
        <v-divider color="amber" class="mt-10 mb-4 mx-4"></v-divider>
        <div class="text-center" style="color: lime">
          <v-icon dark mr-5>mdi-map-marker-plus</v-icon>Service Provider
          Specialization
        </div>
        <v-textarea
          v-model="description"
          label="Service Provider Specialized In"
          :counter="200"
          :error-messages="descriptionErrors"
          dark
          required
          @load="$v.description.$touch()"
          @input="$v.description.$touch()"
          @blur="$v.description.$touch()"
        ></v-textarea>
        <h4 class="text-center" style="color: white">Services</h4>
        <span
          v-if="$v.services.$invalid"
          style="color: lime; font-size: 12px"
          mb-4
        >
          <v-icon small color="amber">mdi-alert</v-icon>Select up to 3 services
          for Ad listing
        </span>
        <v-chip-group
          label="Services (maximum 3)"
          multiple
          max="3"
          column
          dark
          v-model="services"
          active-class="purple accent-3"
          :error-messages="serviceErrors"
          @load="$v.services.$touch()"
          @input="$v.services.$touch()"
          @blur="$v.services.$touch()"
          @click="$v.services.touch()"
        >
          <v-chip v-for="service in goodayServices" :key="service">{{
            service
          }}</v-chip>
        </v-chip-group>
        <v-divider color="amber" class="mt-10 mb-4 mx-4"></v-divider>
        <!-- Experience -->
        <h4 class="text-center" style="color: white">Years of Experience</h4>
        <div class="text-center" style="color: lime">
          Service Provider Years of Experience
        </div>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="experience"
            label="Years of Experience"
            dark
            required
            type="number"
            min="0"
            step="1"
            :error-messages="experienceError"
            @input="$v.experience.$touch()"
            @blur="$v.experience.$touch()"
          ></v-text-field>
        </v-col>

        <v-container>
          <!-- IS Etalem Checkbox -->
          <v-checkbox
            v-model="isEtalem"
            :label="'Etalem Service Provider'"
            color="#E21E81"
            hide-details="auto"
            dark
          >
            <template v-slot:label>
              <span>Etalem Service Provider</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="ml-2">
                    mdi-information
                  </v-icon>
                </template>
                <span
                  >This indicates whether the onboarded service provider is part
                  of Etalem.</span
                >
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-container>
        <v-divider color="amber" class="mt-10 mb-4 mx-4"></v-divider>
        <h4 class="text-center" style="color: white">
          Service Provider Location
        </h4>
        <span
          v-if="$v.providerLocation.$invalid"
          style="color: lime; font-size: 12px"
        >
          <v-icon small color="amber">mdi-alert</v-icon>
          Service Provider's approximate location is required (Enter a location
          name in Addis Ababa that is convenient for the service provider to
          work, and select from the drop down suggested by Google Maps)
        </span>
        <google-map
          :fields="['geometry']"
          :error-messages="providerLocationErrors"
          @load="$v.providerLocation.$touch()"
          @input="$v.providerLocation.$touch()"
          @blur="$v.providerLocation.$touch()"
          v-on:locationData="handleLocationData($event)"
        />
        <v-divider color="amber" class="mt-10 mb-4 mx-4"></v-divider>
        <h4 class="text-center" style="color: white">
          Training Interest & Emergency Contact
        </h4>
        <v-autocomplete
          v-model="trainingPreference"
          :items="trainingOptions"
          label="Select Training preference"
          dark
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="selectedMarketingChannel"
          :items="marketingChannels"
          label="Marketing Channel"
          dark
          required
          :error-messages="marketingChannelErrors"
          @input="$v.selectedMarketingChannel.$touch()"
          @blur="$v.selectedMarketingChannel.$touch()"
        >
        </v-autocomplete>
        <v-text-field
          v-model="emergencyContactName"
          label="Emergency Contact Name"
          :counter="20"
          :error-messages="emergencyContactNameErrors"
          dark
          required
          @input="$v.emergencyContactName.$touch()"
          @blur="$v.emergencyContactName.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="emergencyContactPhone"
          label="Emergency Contact Phone"
          :counter="13"
          :error-messages="emergencyContactPhoneErrors"
          dark
          required
          @input="$v.emergencyContactPhone.$touch()"
          @blur="$v.emergencyContactPhone.$touch()"
        ></v-text-field>
        <v-divider color="amber" class="mt-10 mb-4 mx-4"></v-divider>
        <!-- Upload Certficate Section Starts Here -->
        <v-row
          align="center"
          justify="center"
          style="color: white"
          class="mt-4 mb-2"
        >
          <h4>Certificates (image/pdf)</h4>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-file-input
              v-for="(_, index) in 3"
              :key="`cert-${index}`"
              @change="(file) => handleCertificateUpload(file, index)"
              label="Upload Certificate"
              outlined
              dark
              dense
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row
          align="center"
          justify="center"
          style="color: white"
          class="mt-4 mb-2"
        >
          <h4>Reference or Recommendation Letters (image/pdf)</h4>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-file-input
              v-for="(_, index) in 2"
              :key="`rec-${index}`"
              @change="(file) => handleRecommendationUpload(file, index)"
              label="Upload Recommendation"
              outlined
              dark
              dense
            ></v-file-input>
          </v-col>
        </v-row>
        <!-- Upload Certficate Section Ends Here -->
        <v-divider color="amber" class="mt-10 mb-4 mx-4"></v-divider>
        <v-checkbox
          v-model="policyAgreement"
          label="Privacy Policy and Terms of Service Agreement"
          dark
          required
          :error-messages="policyAgreementErrors"
          @input="$v.policyAgreement.$touch()"
          @blur="$v.policyAgreement.$touch()"
        ></v-checkbox>
        <ul>
          <li><a href="https://gooday.io/#/privacy">Privacy Policy</a></li>
          <li>
            <a href="https://sites.google.com/gooday.io/termsofuse"
              >Terms of Service</a
            >
          </li>
        </ul>
        <!-- <v-text-field
        v-if="showVerifyCode"
        label="Enter SMS code"
        v-model="verifyCode"
        dark></v-text-field>
        <p v-if="verifyError"
      style="color: red; font-size:12px">Invalid SMS token, request a token again!</p>-->

        <!-- <v-divider color="amber" class="my-4 mx-4"></v-divider>
        <div class="text-center" style="color: lime">
          <v-icon dark mr-5>mdi-account-group</v-icon> References
        </div>
        <span style="color: white"
          >(Optional) Add 2 references ("የስራ ምስክር") that can provide positive
          testimony to the personal character, work ethic or quality of the
          service provider. Adding this information helps in getting more hiring
          opportunities for the service provider.</span
        >
        <div class="text-center" style="color: white" mt-4>Reference 1</div>
        <v-text-field
          v-model="ref1FirstName"
          label="First Name"
          :counter="10"
          dark
        ></v-text-field>
        <v-text-field
          v-model="ref1LastName"
          label="Last Name"
          :counter="10"
          dark
        ></v-text-field>
        <v-text-field
          v-model="ref1PhoneNumber"
          hint="0911121314"
          label="Phone Number"
          :counter="10"
          dark
        ></v-text-field>
        <div class="text-center" style="color: white">Reference 2</div>
        <v-text-field
          v-model="ref2FirstName"
          label="First Name"
          :counter="10"
          dark
        ></v-text-field>
        <v-text-field
          v-model="ref2LastName"
          label="Last Name"
          :counter="10"
          dark
        ></v-text-field>
        <v-text-field
          v-model="ref2PhoneNumber"
          label="Phone Number"
          hint="0911121314"
          :counter="10"
          dark
        ></v-text-field> -->
        <span v-if="!$v.$invalid" style="color: #d500f9; font-size: 12px">
          <v-icon small color="#D500F9">mdi-information-outline</v-icon>
          Form is complete, Click Onboard to register service provider
        </span>
        <v-progress-linear
          v-if="!$v.$invalid"
          indeterminate
          rounded
          height="6"
          color="purple accent-3"
        ></v-progress-linear>
        <!-- <span
        v-if="phoneExists"
        style="color: lime; font-size:12px">
        <v-icon small color="amber">mdi-alert</v-icon>
        An account with this phone number exists
      </span>-->
      </form>
      <!-- <span
    v-if="routedNewUser && $v.$invalid"
    style="color: #FFC107; font-size:12px">
    <v-icon small color="amber">mdi-alert</v-icon>
    The phone number used for sign-in is new to the system, you need to register!
    </span>-->
      <v-card-actions class="justify-center">
        <v-btn @click="register" class="mr-10" :disabled="$v.$invalid"
          >Onboard</v-btn
        >
        <v-btn @click="clear" class="ml-10">Clear Form</v-btn>
      </v-card-actions>
    </v-card>
  </v-main>
</template>

<script>
// import { required,maxLength,email,minLength,numeric } from 'vuelidate/lib/validators';
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
import firebase, { firestore } from "firebase/app";
import VEthiopianDatePicker from "vuetify-ethiopian-calendar";
import { firebaseAuthMixins } from "./mixins/firebaseAuth";
// import { ethiopiaCities } from '../data/cities';
// import { availableServices } from '../data/services';
import ImageCropping from "./ImageCropping.vue";
import GMap from "./GMap.vue";
import "firebase/storage";

const ethiopianDate = require("ethiopian-date");

export default {
  components: {
    "image-cropping": ImageCropping,
    "google-map": GMap,
    "v-ethiopian-date-picker": VEthiopianDatePicker,
  },
  mixins: [firebaseAuthMixins],
  data() {
    return {
      onboardInProgress: false,
      goodayServices: null,
      firstName: "",
      lastName: "",
      //   phone: '',
      // age: 0,
      gender: "",
      //   confirmPhone: '',
      email: "noemail@gooday.ai",
      //  confirmEmail: 'noemail@gooday.ai',
      //   city: '',
      profilePictureImageData: null,
      identificationDocImageData: null,
      // adImageData: null,
      profilePicURL: null,
      idDocPicURL: null,
      // adPicURL: null,
      description: "",
      services: [],
      educationalLevel: "",
      experience: 0,
      providerLocation: null,
      neighborhood: "",
      policyAgreement: false,
      userOnboardData: {},
      adOnboardData: {},
      isEditing: false,
      trainingPreference: "",
      selectedMarketingChannel: "",
      nationalId: null,
      emergencyContactName: "",
      emergencyContactPhone: "",
      showDatePicker: false,
      pickedDate: null,
      gregorianDate: null,
      activePicker: "YEAR",
      dobMin: null,
      dobMax: null,
      certificateFiles: [],
      recommendationFiles: [],
      isEtalem: false,
    };
  },

  validations: {
    firstName: {
      required,
      maxLen: maxLength(10),
    },
    lastName: {
      required,
      maxLen: maxLength(10),
    },
    // ref1FirstName: {
    //   required,
    //   maxLen: maxLength(10),
    // },
    // ref1LastName: {
    //   required,
    //   maxLen: maxLength(10),
    // },
    // ref2FirstName: {
    //   required,
    //   maxLen: maxLength(10),
    // },
    // ref2LastName: {
    //   required,
    //   maxLen: maxLength(10),
    // },
    pickedDate: {
      required,
    },
    // age: {
    //   required,
    //   numeric,
    //   minValue: minValue(14),
    //   maxValue: maxValue(69),
    // },
    gender: {
      required,
    },
    email: {
      required,
      email,
    },
    educationalLevel: {
      required,
    },
    // confirmEmail: {
    //   required,
    //   email,
    //   sameAs: sameAs('email'),
    // },
    // ref1PhoneNumber: {
    //   required,
    //   numeric,
    //   minLen: minLength(10),
    //   maxLen: maxLength(10),
    // },
    // ref2PhoneNumber: {
    //   required,
    //   numeric,
    //   minLen: minLength(10),
    //   maxLen: maxLength(10),
    // },
    // confirmPhone: {
    //   required,
    //   sameAs: sameAs('phone'),
    // },
    profilePictureImageData: {
      required,
    },
    identificationDocImageData: {
      required,
    },
    // city: {
    //   required,
    // },
    description: {
      required,
      maxLen: maxLength(200),
    },
    services: {
      required,
    },
    experience: {
      required,
      numeric,
    },
    // adImageData: {
    //   required,
    // },
    emergencyContactName: {
      required,
      maxLen: maxLength(20),
    },
    emergencyContactPhone: {
      required,
      numeric,
      minLen: minLength(10),
      maxLen: maxLength(10),
    },
    providerLocation: {
      required,
    },
    // trainingOptions: {
    //   required,
    // },
    selectedMarketingChannel: {
      required,
    },
    policyAgreement: {
      checked(val) {
        return val;
      },
    },
  },
  methods: {
    handleLocationData(locationData) {
      console.log("Received location data:", locationData);
      this.providerLocation = {
        lat: locationData.lat,
        lng: locationData.lng,
        geohash: locationData.geohash,
      };

      // Use the formatted_address from Google Places
      this.neighborhood = locationData.formatted_address || "Addis Ababa";
      console.log("Set neighborhood to:", this.neighborhood);
    },
    convertDate() {
      if (this.pickedDate) {
        const [ethiopianYear, ethiopianMonth, ethiopianDay] = this.pickedDate
          .split("-")
          .map(Number);

        // Convert Ethiopian date to Gregorian
        const gd = ethiopianDate.toGregorian(
          ethiopianYear,
          ethiopianMonth,
          ethiopianDay
        );
        console.log("Gregorian Date Object:", gd);
        this.gregorianDate = gd.join("-");
        this.closeDatePicker();
      }
    },
    setDobLimits() {
      const today = new Date();

      // Calculate dobMin (65 years ago)
      const dobMinGregorian = new Date(
        today.getFullYear() - 65,
        today.getMonth(),
        today.getDate()
      );
      const [minYear, minMonth, minDay] = ethiopianDate.toEthiopian(
        dobMinGregorian.getUTCFullYear(),
        dobMinGregorian.getUTCMonth() + 1,
        dobMinGregorian.getUTCDate()
      );
      this.dobMin = `${minYear}-${String(minMonth).padStart(2, "0")}-${String(
        minDay
      ).padStart(2, "0")}`;

      // Calculate dobMax (14 years ago)
      const dobMaxGregorian = new Date(
        today.getFullYear() - 14,
        today.getMonth(),
        today.getDate()
      );
      const [maxYear, maxMonth, maxDay] = ethiopianDate.toEthiopian(
        dobMaxGregorian.getUTCFullYear(),
        dobMaxGregorian.getUTCMonth() + 1,
        dobMaxGregorian.getUTCDate()
      );
      this.dobMax = `${maxYear}-${String(maxMonth).padStart(2, "0")}-${String(
        maxDay
      ).padStart(2, "0")}`;
    },
    openDatePicker() {
      this.showDatePicker = true;
    },
    closeDatePicker() {
      this.showDatePicker = false;
    },
    getServices() {
      const db = firebase.firestore();
      db.collection("services")
        .doc("gooday_classified_services")
        .get()
        .then((servicesSnapshot) => {
          const serviceList = [];
          servicesSnapshot.data().services.forEach((c) => {
            serviceList.push(...c.services);
          });
          console.log(serviceList);
          this.goodayServices = [...new Set(serviceList)].sort();
          console.timeLog(this.goodayServices);
        })
        .catch((error) => console.log(error));
    },
    clear() {
      this.firstName = "";
      this.lastName = "";
      this.phone = "";
      this.pickedDate = null;
      //this.age = 0;
      this.experience = 0;
      this.gender = "";
      this.confirmPhone = "";
      this.profilePictureImageData = null;
      this.identificationDocImageData = null;
      // this.adImageData = null;
      this.trainingPreference = "";
      this.selectedMarketingChannel = "";
      this.nationalId = null;
      this.educationalLevel = "";
      this.description = "";
      this.services = [];
      this.emergencyContactName = "";
      this.emergencyContactPhone = "";
      this.providerLocation = null;
      this.neighborhood = "";
      this.policyAgreement = false;
      this.certificateFiles = [];
      this.recommendationFiles = [];
    },
    register() {
      // quit if onboarding badge number is null
      if (this.$store.getters["auth/badgeNumber"] == null) return;
      this.onboardInProgress = true;
      const imageName = "userData.jpeg";

      // uploading profile picture
      this.profilePictureImageData.toBlob(async (imageBlob) => {
        const storageRef = firebase.storage().ref();
        const uploadTask = storageRef
          .child(`profile/${firebase.auth().currentUser.uid}/${imageName}`)
          .put(imageBlob);

        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING:
                console.log("Upload is running");
                break;
              case firebase.storage.TaskState.ERROR:
                console.log("Error occurred uploading image data");
                break;
              case firebase.storage.TaskState.SUCCESS:
                console.log("Upload is successful");
                break;
              default:
                console.log("Something weird happened");
                break;
            }
          },
          (error) => {
            switch (error.code) {
              case "storage/unauthorized":
                console.log("User doesn't have permission");
                break;
              case "storage/canceled":
                console.log("Upload is canceled");
                break;
              case "storage/unknown":
                console.log("Unknown error");
                break;
              default:
                console.log("Unexpected error occurred");
                break;
            }
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.profilePicURL = downloadURL;

              // uploading identification doc
              this.identificationDocImageData.toBlob(async (imageBlob2) => {
                const uploadTask2 = storageRef
                  .child(
                    `identification/${
                      firebase.auth().currentUser.uid
                    }/${imageName}`
                  )
                  .put(imageBlob2);

                uploadTask2.on(
                  firebase.storage.TaskEvent.STATE_CHANGED,
                  (snapshot) => {
                    const progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(`Upload is ${progress}% done`);
                    switch (snapshot.state) {
                      case firebase.storage.TaskState.PAUSED:
                        console.log("Upload is paused");
                        break;
                      case firebase.storage.TaskState.RUNNING:
                        console.log("Upload is running");
                        break;
                      case firebase.storage.TaskState.ERROR:
                        console.log("Error occurred uploading image data");
                        break;
                      case firebase.storage.TaskState.SUCCESS:
                        console.log("Upload is successful");
                        break;
                      default:
                        console.log("Something weird happened");
                        break;
                    }
                  },
                  (error) => {
                    switch (error.code) {
                      case "storage/unauthorized":
                        console.log("User doesn't have permission");
                        break;
                      case "storage/canceled":
                        console.log("Upload is canceled");
                        break;
                      case "storage/unknown":
                        console.log("Unknown error");
                        break;
                      default:
                        console.log("Unexpected error occurred");
                        break;
                    }
                  },
                  () => {
                    uploadTask2.snapshot.ref
                      .getDownloadURL()
                      .then((downloadURL2) => {
                        this.idDocPicURL = downloadURL2;

                        // Replace the certificate and recommendation upload logic with:
                        const uploadPromises = [];

                        // Upload certificates
                        this.certificateFiles.forEach((certFile, index) => {
                          if (certFile && certFile.file) {
                            const uploadPromise = new Promise(
                              (resolve, reject) => {
                                const certUploadTask = storageRef
                                  .child(
                                    `certificates/${
                                      firebase.auth().currentUser.uid
                                    }/${certFile.name}`
                                  )
                                  .put(certFile.file);

                                certUploadTask.on(
                                  firebase.storage.TaskEvent.STATE_CHANGED,
                                  (snapshot) => {
                                    const uploadProgress =
                                      (snapshot.bytesTransferred /
                                        snapshot.totalBytes) *
                                      100;
                                    console.log(
                                      `Upload is ${uploadProgress}% done`
                                    );
                                  },
                                  (error) => {
                                    console.log(
                                      `Error uploading certificate ${
                                        index + 1
                                      }:`,
                                      error
                                    );
                                    reject(error);
                                  },
                                  async () => {
                                    try {
                                      const certDownloadURL =
                                        await certUploadTask.snapshot.ref.getDownloadURL();

                                      if (!this.certificateFiles) {
                                        this.$set(this, "certificateFiles", []);
                                      }

                                      this.$set(this.certificateFiles, index, {
                                        file: certFile.file,
                                        name: certFile.name,
                                        url: certDownloadURL,
                                      });

                                      console.log(
                                        `Certificate ${index + 1} stored:`,
                                        this.certificateFiles[index]
                                      );
                                      resolve(certDownloadURL);
                                    } catch (error) {
                                      console.error(
                                        `Error getting download URL for certificate ${
                                          index + 1
                                        }:`,
                                        error
                                      );
                                      reject(error);
                                    }
                                  }
                                );
                              }
                            );

                            uploadPromises.push(uploadPromise);
                          }
                        });

                        // Upload recommendations
                        this.recommendationFiles.forEach((recFile, index) => {
                          if (recFile && recFile.file) {
                            const recUploadTask = storageRef
                              .child(
                                `recommendations/${
                                  firebase.auth().currentUser.uid
                                }/${recFile.name}`
                              )
                              .put(recFile.file);

                            uploadPromises.push(
                              new Promise((resolve, reject) => {
                                recUploadTask.on(
                                  firebase.storage.TaskEvent.STATE_CHANGED,
                                  (snapshot) => {
                                    const uploadProgress =
                                      (snapshot.bytesTransferred /
                                        snapshot.totalBytes) *
                                      100;
                                    console.log(
                                      `Upload is ${uploadProgress}% done`
                                    );
                                  },
                                  (error) => {
                                    console.log(
                                      `Error uploading recommendation ${
                                        index + 1
                                      }:`,
                                      error
                                    );
                                    reject(error);
                                  },
                                  () => {
                                    recUploadTask.snapshot.ref
                                      .getDownloadURL()
                                      .then((recDownloadURL) => {
                                        // Store URL in our array instead of mutating the parameter
                                        this.recommendationFiles[index] = {
                                          ...recFile,
                                          url: recDownloadURL,
                                        };
                                        resolve();
                                      });
                                  }
                                );
                              })
                            );
                          }
                        });

                        // Wait for all uploads to complete
                        Promise.all(uploadPromises)
                          .then(() => {
                            // Update the user data with the new URLs
                            this.userOnboardData = {
                              age: parseInt(this.age, 10),
                              dateOfBirth: this.dateOfBirth,
                              createdAt: firestore.Timestamp.now(),
                              email: this.email,
                              firstName: this.firstName,
                              lastName: this.lastName,
                              geoLoc: {
                                geohash: this.providerLocation.geohash,
                                geopoint: this.serviceLocation,
                              },
                              neighborhood: this.neighborhood,
                              identificationPic: this.idDocPicURL,
                              lastUpdate: firestore.Timestamp.now(),
                              phoneNumber:
                                firebase.auth().currentUser.phoneNumber,
                              policyAgree: this.policyAgreement,
                              profilePic: this.profilePicURL,
                              educationalLevel: this.educationalLevel,
                              experience: this.experience,
                              trainingPreference: this.trainingPreference,
                              selectedMarketingChannel:
                                this.selectedMarketingChannel,
                              nationalId: this.nationalId,
                              certificate1:
                                this.certificateFiles[0]?.url || null,
                              certificate2:
                                this.certificateFiles[1]?.url || null,
                              certificate3:
                                this.certificateFiles[2]?.url || null,
                              recommendation1:
                                this.recommendationFiles[0]?.url || null,
                              recommendation2:
                                this.recommendationFiles[1]?.url || null,
                              rating: 5,
                              reportingCount: 0,
                              reviewingCount: 0,
                              serviceProvider: true,
                              sex: this.gender,
                              emergencyContactName: this.emergencyContactName,
                              emergencyContactPhone: this.emergencyContactPhone,
                              isEtalem: this.isEtalem,
                              verifiedUser:
                                !this.$store.getters["auth/inTraining"],
                              onboardingAgentBadgeNumber:
                                this.$store.getters["auth/badgeNumber"],
                            };

                            this.adOnboardData = {
                              approvedUser:
                                !this.$store.getters["auth/inTraining"],
                              createdAt: firestore.Timestamp.now(),
                              firstName: this.firstName,
                              lastName: this.lastName,
                              experience: this.experience,
                              educationalLevel: this.educationalLevel,
                              selectedMarketingChannel:
                                this.selectedMarketingChannel,
                              nationalId: this.nationalId,
                              geoLoc: {
                                geohash: this.providerLocation.geohash,
                                geopoint: this.serviceLocation,
                              },
                              neighborhood: this.neighborhood,
                              lastUpdate: firestore.Timestamp.now(),
                              phoneNumber:
                                firebase.auth().currentUser.phoneNumber,
                              policyAgree: true,
                              profilePicURL: this.profilePicURL,
                              services: this.providerServices,
                              summary: this.description,
                              emergencyContactName: this.emergencyContactName,
                              emergencyContactPhone: this.emergencyContactPhone,
                              isEtalem: this.isEtalem,
                              certificate1:
                                this.certificateFiles[0]?.url || null,
                              certificate2:
                                this.certificateFiles[1]?.url || null,
                              certificate3:
                                this.certificateFiles[2]?.url || null,
                              recommendation1:
                                this.recommendationFiles[0]?.url || null,
                              recommendation2:
                                this.recommendationFiles[1]?.url || null,
                            };
                            const db = firebase.firestore();
                            db.collection("users")
                              .doc(firebase.auth().currentUser.uid)
                              .set(this.userOnboardData)
                              .then((docRef) => {
                                console.log(
                                  "User data written with ID: ",
                                  docRef
                                );
                                db.collection("goodays")
                                  .doc(firebase.auth().currentUser.uid)
                                  .set(this.adOnboardData)
                                  .then(() => {
                                    const dateNow = new Date();
                                    dateNow.setDate(dateNow.getDate() + 180);
                                    db.collection("billing")
                                      .doc(firebase.auth().currentUser.uid)
                                      .set({
                                        paidThrough:
                                          firestore.Timestamp.fromDate(dateNow),
                                      })
                                      .then(() => {
                                        this.$store.dispatch("auth/signOut");
                                        // eslint-disable-next-line no-alert
                                        alert(
                                          "Provider is successfully onboarded, the service provider can now be found on GoodayOn App"
                                        );
                                      })
                                      .catch((error) => {
                                        console.log(error);
                                      });
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          })
                          .catch((error) => {
                            console.error("Error uploading files:", error);
                          });
                      });
                  }
                );
              });
            });
          }
        );
      });
    },
    handleCertificateUpload(file, index) {
      if (!file) {
        console.log("No file selected for certificate", index);
        return;
      }

      // Check if user is authenticated
      const { currentUser } = firebase.auth();
      if (!currentUser) {
        console.error("User not authenticated");
        this.$emit("auth-error", "Please sign in to upload files");
        return;
      }

      // Validate file size (5MB limit)
      const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > MAX_FILE_SIZE) {
        this.$emit("upload-error", `File size must be less than 5MB`);
        return;
      }

      console.log(`Starting upload for certificate ${index + 1}:`, file.name);

      const timestamp = Date.now();
      const fileName = `Certificate${timestamp}`;
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(
        `certificates/${currentUser.uid}/${fileName}`
      );

      // Create a promise for this upload
      const uploadPromise = new Promise((resolve, reject) => {
        const uploadTask = fileRef.put(file);

        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Certificate ${index + 1} upload is ${progress}% done`);
          },
          (error) => {
            console.error(`Error uploading certificate ${index + 1}:`, error);
            let errorMessage = error.message;
            if (error.code === "storage/unauthorized") {
              errorMessage =
                "You do not have permission to upload files. Please sign in again.";
            }
            this.$emit(
              "upload-error",
              `Error uploading certificate ${index + 1}: ${errorMessage}`
            );
            reject(error);
          },
          async () => {
            try {
              const downloadURL =
                await uploadTask.snapshot.ref.getDownloadURL();

              if (!this.certificateFiles) {
                this.$set(this, "certificateFiles", []);
              }

              this.$set(this.certificateFiles, index, {
                file,
                name: fileName,
                url: downloadURL,
              });

              console.log(
                `Certificate ${index + 1} stored:`,
                this.certificateFiles[index]
              );
              resolve(downloadURL);
            } catch (error) {
              console.error(
                `Error getting download URL for certificate ${index + 1}:`,
                error
              );
              this.$emit(
                "download-error",
                `Error getting download URL for certificate ${index + 1}: ${
                  error.message
                }`
              );
              reject(error);
            }
          }
        );
      });

      if (!this.uploadPromises) {
        this.$set(this, "uploadPromises", []);
      }
      this.uploadPromises.push(uploadPromise);
    },
    handleRecommendationUpload(file, index) {
      if (!file) {
        console.log("No file selected for recommendation", index);
        return;
      }

      // Check if user is authenticated
      const { currentUser } = firebase.auth();
      if (!currentUser) {
        console.error("User not authenticated");
        this.$emit("auth-error", "Please sign in to upload files");
        return;
      }

      // Validate file size (5MB limit)
      const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > MAX_FILE_SIZE) {
        this.$emit("upload-error", `File size must be less than 5MB`);
        return;
      }

      console.log(
        `Starting upload for recommendation ${index + 1}:`,
        file.name
      );

      const timestamp = Date.now();
      const fileName = `Recommendation${timestamp}`;
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(
        `recommendations/${currentUser.uid}/${fileName}`
      );

      // Create a promise for this upload
      const uploadPromise = new Promise((resolve, reject) => {
        const uploadTask = fileRef.put(file);

        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(
              `Recommendation ${index + 1} upload is ${progress}% done`
            );
          },
          (error) => {
            console.error(
              `Error uploading recommendation ${index + 1}:`,
              error
            );
            let errorMessage = error.message;
            if (error.code === "storage/unauthorized") {
              errorMessage =
                "You do not have permission to upload files. Please sign in again.";
            }
            this.$emit(
              "upload-error",
              `Error uploading recommendation ${index + 1}: ${errorMessage}`
            );
            reject(error);
          },
          async () => {
            try {
              const downloadURL =
                await uploadTask.snapshot.ref.getDownloadURL();

              if (!this.recommendationFiles) {
                this.$set(this, "recommendationFiles", []);
              }

              this.$set(this.recommendationFiles, index, {
                file,
                name: fileName,
                url: downloadURL,
              });

              console.log(
                `Recommendation ${index + 1} stored:`,
                this.recommendationFiles[index]
              );
              resolve(downloadURL);
            } catch (error) {
              console.error(
                `Error getting download URL for recommendation ${index + 1}:`,
                error
              );
              this.$emit(
                "download-error",
                `Error getting download URL for recommendation ${index + 1}: ${
                  error.message
                }`
              );
              reject(error);
            }
          }
        );
      });

      if (!this.uploadPromises) {
        this.$set(this, "uploadPromises", []);
      }
      this.uploadPromises.push(uploadPromise);
    },
  },

  computed: {
    age() {
      const dob = Date.parse(this.gregorianDate);
      const ageDate = new Date(Date.now() - dob);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    dateOfBirth() {
      const dob = Date.parse(this.gregorianDate);
      return firestore.Timestamp.fromMillis(dob);
    },
    providerServices() {
      const ps = [];
      this.services.forEach((element) => {
        ps.push(this.goodayServices[element]);
      });
      return ps;
    },
    serviceLocation() {
      const locationPoint = new firebase.firestore.GeoPoint(
        this.providerLocation.lat,
        this.providerLocation.lng
      );
      return locationPoint;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      /* eslint-disable no-unused-expressions */
      !this.$v.firstName.maxLen &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.firstName.required && errors.push("Name is Required");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.maxLen &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.lastName.required && errors.push("Name is Required");
      return errors;
    },
    emergencyContactNameErrors() {
      const errors = [];
      if (!this.$v.emergencyContactName.$dirty) return errors;
      !this.$v.emergencyContactName.maxLen &&
        errors.push(
          "Emergency Contact name must be at most 20 characters long"
        );
      !this.$v.emergencyContactName.required &&
        errors.push("Emergenecy contact name is Required");
      return errors;
    },
    emergencyContactPhoneErrors() {
      const errors = [];
      if (!this.$v.emergencyContactPhone.$dirty) return errors;
      !this.$v.emergencyContactPhone.maxLen &&
        errors.push("Phone number should be 10 digits long");
      !this.$v.emergencyContactPhone.minLen &&
        errors.push("Phone number should be 10 digits long");
      !this.$v.emergencyContactPhone.required &&
        errors.push("phone number is Required");
      !this.$v.emergencyContactPhone.numeric &&
        errors.push("Only numeric digits allowed, example 0911121314");
      return errors;
    },
    experienceError() {
      const errors = [];
      if (!this.$v.experience.$dirty) return errors;
      !this.$v.experience.required && errors.push("Experiance is required");
      return errors;
    },
    trainingOptions() {
      return [
        "Culinary",
        "House Keeping",
        "Home Appliance Repair",
        "Plumber",
        "Gypsum Work",
        "Painting",
        "Office Machine Repair",
      ];
    },
    marketingChannels() {
      return [
        "Tiktok",
        "LinkedIn",
        "Facebook",
        "Instagram",
        "Friends/Family",
        "Previous Customer",
        "Direct Marketing",
        "Twitter",
        "Telegram",
        "Radio",
        "TV",
        "Partners",
        "Internet Ad",
        "Unknown",
      ];
    },
    pickedDateErrors() {
      const errors = [];
      if (!this.$v.pickedDate.$dirty) return errors;
      !this.$v.pickedDate.required && errors.push("Date of Birth is required");
      return errors;
    },
    educationalLevelErrors() {
      const errors = [];
      if (!this.$v.educationalLevel.$dirty) return errors;
      !this.$v.educationalLevel.required &&
        errors.push("Education level is required");
      return errors;
    },
    marketingChannelErrors() {
      const errors = [];
      if (!this.$v.selectedMarketingChannel.$dirty) return errors;
      !this.$v.selectedMarketingChannel.required &&
        errors.push("Marketing Channel is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be a valid E-mail address");
      !this.$v.email.required && errors.push("E-mail is Required");
      return errors;
    },
    policyAgreementErrors() {
      const errors = [];
      if (!this.$v.policyAgreement.$dirty) return errors;
      !this.$v.policyAgreement.checked &&
        errors.push("You must agree to policy to continue!");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("Gender is required field");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      /* eslint-disable no-unused-expressions */
      !this.$v.description.maxLen &&
        errors.push("Ad Description can be at most 200 characters long");
      !this.$v.description.required &&
        errors.push("Ad Description is Required");
      return errors;
    },
    providerLocationErrors() {
      const errors = [];
      if (!this.$v.providerLocation.$dirty) return errors;
      !this.$v.providerLocation.required &&
        errors.push(
          "Drop pin on the map and select the provider's approximate service area"
        );
      this.providerLocation == null &&
        errors.push(
          "Drop pin on the map and select the provider's approximate service area"
        );
      return errors;
    },
    serviceErrors() {
      const errors = [];
      if (!this.$v.services.$dirty) return errors;
      !this.$v.services.required && errors.push("Select atleast one service");
      return errors;
    },
    profilePictureErrors() {
      const errors = [];
      if (!this.$v.profilePictureImageData.$dirty) return errors;
      !this.$v.profilePictureImageData.required &&
        errors.push("Profile picture is required");
      return errors;
    },
    idDocPictureErrors() {
      const errors = [];
      if (!this.$v.identificationDocImageData.$dirty) return errors;
      !this.$v.identificationDocImageData.required &&
        errors.push("Identification document picture is required");
      return errors;
    },
    formClear() {
      return !this.$v.$invalid;
    },
    routedNewUser() {
      return this.$store.getters["auth/routedNewUser"];
    },
    genders() {
      return ["Female", "Male"];
    },
    educationalLevels() {
      return [
        "Primary School (1-8)",
        "High School (9-10)",
        "Preparatory (11-12)",
        "Certificate",
        "Diploma",
        "Degree",
        "None",
      ];
    },
  },
  documentsMap() {
    return [
      {
        fileName: "recommendation1",
        fileData: this.recommendationFiles[0].file,
      },
      {
        fileName: "recommendation2",
        fileData: this.recommendationFiles[1].file,
      },
      { fileName: "certificate1", fileData: this.certificateFiles[0].file },
      { fileName: "certificate2", fileData: this.certificateFiles[1].file },
      { fileName: "certificate3", fileData: this.certificateFiles[2].file },
    ];
  },
  created() {
    this.setDobLimits();
    this.getServices();
  },
};
</script>

<style scoped>
.signup-form {
  max-width: 700px;
  margin: 10px auto;
  border: 1px solid #eee;
  padding: 10px;
  box-shadow: 0 2px 3px #ccc;
}
.content {
  color: #ffffff;
}
</style>
