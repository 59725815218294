<template>
  <v-main>
    <v-row>
      <VueGooglePlaces
        id="map"
        :options="{
          fields: ['geometry', 'formatted_address', 'name', 'place_id'],
        }"
        types="establishment"
        country="et"
        label=""
        @placechanged="getAddressData"
        style="width: 100%"
      >
        <v-text-field
          dense
          dark
          hide-details=""
          class="ma-0 pb-4 pr-8 pl-8"
          label="Enter location name"
          outlined
        />
      </VueGooglePlaces>
    </v-row>
    <v-row>
      <GmapMap
        :center="center"
        :zoom="13"
        map-type-id="roadmap"
        style="width: 100%; height: 300px"
      >
        <GmapMarker :position="position" @click="center = position" />
      </GmapMap>
    </v-row>
  </v-main>
</template>

<script>
import Geohash from "latlon-geohash";

export default {
  data: () => ({
    position: null,
    center: { lat: 9.005401, lng: 38.763611 },
    lastEmittedAddress: null,
  }),
  methods: {
    getAddressData(addressData) {
      console.log("Full address data:", addressData);

      const locationData = {
        lat: addressData.latitude,
        lng: addressData.longitude,
        geohash: Geohash.encode(addressData.latitude, addressData.longitude, 9),
        formatted_address:
          addressData.name || addressData.formatted_address || "Addis Ababa",
      };

      // Store the emitted address
      this.lastEmittedAddress = locationData.formatted_address;

      console.log("Emitting location data:", locationData);
      this.$emit("locationData", locationData);

      // Update map position
      this.position = {
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.center = this.position;
    },

    // Modified to prevent overriding the address
    locationData() {
      if (!this.position || this.lastEmittedAddress) return;

      const geohash = Geohash.encode(this.position.lat, this.position.lng, 9);
      this.$emit("locationData", {
        lat: this.position.lat,
        lng: this.position.lng,
        geohash,
        formatted_address: this.lastEmittedAddress, // Use stored address
      });
    },
  },
  updated() {
    if (this.position && !this.lastEmittedAddress) {
      this.locationData();
    }
  },
  // Reset lastEmittedAddress when component is destroyed
  beforeDestroy() {
    this.lastEmittedAddress = null;
  },
};
</script>
